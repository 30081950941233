import React from "react"
import styled from 'styled-components'

const PrivacyPolicy = styled.div`
  background-color: white;
  max-width:1200px;
  margin:auto;
  padding-top:80px;
  @media (max-width: 1000px) {
    max-width:90%;
  }
`

const MainHeading = styled.h1`
  text-align:${props => props.center ? 'center;' : ''};
  font-family: 'RomanaBeckerDemi';
  padding-top:${props => props.paddingTop ? '30px' : ''};
  padding-bottom:${props => props.paddingBot ? '30px' : ''};
`

const Text = styled.p`
  text-align:${props => props.center ? 'center;' : ''};
  font-family: 'Poppins','Arial';
  line-height: 1.6;
  margin-top: ${props => props.mt ? props.mt : ''};
  margin-bottom: ${props => props.mb ? props.mb : ''};
  margin-right: ${props => props.mr ? props.mr : ''};
  font-style: ${props => props.italic ? "italic" : 'normal'};
  font-weight: ${props => props.weight ? props.weight : 'normal'};
`



const Terms = (props) => {




  return (
    <>

      <PrivacyPolicy>
        <MainHeading center>Terms of Service</MainHeading>
        <Text>
      

<div>
   <h1>Terms of Service</h1>
   <p class="Text-sc-1j3qsx4-0 dGrxvY">last updated August 26, 2020</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Welcome to Kisstasy! Kisstasy.com, Inc. (“Kisstasy”) provides our website located at Kisstasy.com (the “Site”) and our audio story services accessible via our Site and our mobile device application (“App”). To make these Terms easier to read, the Site, our services and App are collectively called the “Services.” Please read these Terms of Service (the “Terms”) carefully becausethey govern your use of our Services.</p>
   <h2>Agreement to Terms</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">By using or visiting https://Kisstasy.com/, or any content, functionality and services made available to you on or through the website, whether as a registered user or as a guest or by agreeing to accept these Terms of Service, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, please do not use the website or the Services made available on or through the website.</p>
   <h2>Privacy Policy</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users. You acknowledge and agree that your use of the Services is subject to our Privacy Policy.</p>
   <h2>Changes to Terms or Services</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">We may modify the Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting the modified Terms on the Site or through other communications. It’s important that you review the Terms whenever we modify them because if you continue to use the Services after we have posted modified Terms on the Site, you are indicating to us that you agree to be bound by the modified Terms. If you don’t agree to be bound by the modified Terms, then you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</p>
   <h2>Arbitration Notice</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs"> Unless you opt-out of arbitration within 30 days of the date you first agree to these terms by following the opt-out procedure specified in the “Arbitration” section below, and except for certain types of disputes described in the “Arbitration“ section below, you agree that disputes between you and Kisstasy will be resolved by binding, individual arbitration and you are waiving your right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding.</p>
   <h2>Eligibility</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You may use the Services only if you are 18 years or older and are not barred from using the Services under applicable law. To make a purchase via the Services (described in the Section titled “Purchases” below), you must be 18 years or older and capable of forming a binding contract.</p>
   <h2>Registration and Account Security</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">If you want to use certain features of the Services you’ll have to create an account (“Account”). You can do this via the App or the Site or through your account with certain third-party social networking services such as Facebook (each, an “SNS Account”). If you choose the SNS Account option we’ll create your Account by extracting from your SNS Account certain personal information such as your name and email address and other personal information that your privacy settings on the SNS Account permit us to access. It’s important that you provide us with accurate, complete and up-to-date information for your Account and you agree to update such information, as needed, to keep it accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate your Account. You agree that you won’t disclose your Account password to anyone and you’ll notify us immediately of any unauthorized use of your Account. You’re responsible for all activities that occur under your Account, whether or not you know about them.</p>
   <h2>Using the Services</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Via the Services, users can select from a variety of options to listen to audio stories. You can also use the paid functionality of the Services to utilize the enhanced functionality of the Services or purchase items that are offered for sale through the Services (the “Products”), as described in further detail under the Section titled “Purchases” below. You understand and agree that the Services, Products and any other information you learn from Kisstasy are not intended, designed, or implied to diagnose, prevent, or treat any condition or disease, to ascertain the state of your health, or to be a substitute for professional medical care. If you use the Services alongside other people, make sure they consent to do so. Not all activities described on the Services or Products are suitable for everyone. Do not use the Services while driving, operating heavy machinery, or performing other tasks that require attention and concentration. You understand and agree that you are solely responsible for your use of the Services.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Subject to these Terms of Use, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable personal license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content for other than purposes of using the Services is expressly prohibited without prior written permission from us. You shall not sell, license, rent, or otherwise use or exploit any Content for commercial use or in any way that violates any third party right. As between you and Kisstasy, Kisstasy shall own all title, ownership rights, and intellectual property rights in and to the Services, and any copies or portions thereof.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">The rights to access and use the service provided by Kisstasy is revoked in jurisdictions where it may be prohibited if any.</p>
   <h2>Purchases, Fees, Payment, Subscriptions, Billing, Renewal and Cancellation</h2>
   <h3>Purchases and Fees</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Some of the services offered by Kisstasy may be made accessible for free while some contents on the platform are offered for a fee – while other Services may be free with optional paid upgrades (collectively, “Paid Services”). By using a Paid Service, you agree to pay the specified fees. Depending on the Paid Service, there may be an annual one-time subscription or recurring subscriptions (“Subscription”). For recurring fees, we’ll bill or charge you for in regular intervals such as monthly, on a pre-pay basis until you cancel, which you can do at any time as described in our cancellation policy herein.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">A description of features associated with Subscriptions is available via the Services. When you purchase a Subscription or a Product (each, a “Transaction”), we may ask you to supply additional information relevant to your Transaction, such as your credit card number, the expiration date of your credit card and your address(es) for billing and delivery (such information, “Payment Information”). You represent and warrant that you have the legal right to use all payment method(s) represented by any such Payment Information. The amounts due and payable by you for a Transaction through the Services will be presented to you before you place your order.</p>
   <h3>Use of Third-Party Payment Service Providers</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Purchases of contents made available to you via the Kisstasy platform and subscriptions to any of the Kisstasy subscription plans can be made using credit cards or other available payment methods as may be made available.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">If you choose to initiate a Transaction via the Services, you authorize us to provide your Payment Information to third-party service providers so we can complete your Transaction and agree</p>
   <ol>
      <li>to pay the applicable fees and any taxes; </li>
      <li>that Kisstasy may charge your credit card or third party payment processing account, including, but not limited to, your account with the app store or distribution platform (like the Apple App Store, Google Play or the Amazon Appstore) where the App is made available (each, an “App Provider”), for verification, pre-authorization and payment purposes; and </li>
      <li>to bear any additional charges that your App Provider, bank or other financial service providers may levy on you as well as any taxes or fees that may apply to your order.</li>
   </ol>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You’ll receive a confirmation email after we confirm the payment for your order. Your order is not binding on Kisstasy until accepted and confirmed by Kisstasy.</p>
   <h3>Purchases and Fees</h3>
   <h2>Subscription</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">
      Your subscription to the Kisstasy service will continue unless and until you choose to cancel, or in the event that we terminate it subject to the terms of this policy. You must provide us with a current, valid, accepted method of payment (as such may be updated from time to time, ”Payment Method”) to use our services. We will bill the applicable subscription fee to your Payment Method. You must cancel your subscription before it renews in order to avoid billing of the next period’s subscription fees to your Payment Method.
   </p>
   <h3>Billing and renewals</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">By starting your subscription to Kisstasy and by providing a Payment  Method, you authorize us to charge you a recurring subscription fee at the then current rate, and any other charges you may incur in connection with your use of our services to your Payment Method. You acknowledge that the amount billed each period may vary (but not materially) from term to term and you authorize us to charge your Payment Method for such varying amounts, which may be billed monthly in one or more charges.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">The subscription fee for our services will be billed at the beginning of the paying portion of your subscription and each period thereafter unless and until you cancel your subscription. We automatically bill your Payment Method each period on or near the calendar day corresponding to the commencement of your paying subscription. Subscription fees are fully earned upon payment. We reserve the right to change the timing of our billing, in particular, as indicated below if your Payment Method has not successfully been processed. In the event that your paying subscription began on a day not contained in a given month, we may bill your Payment Method on a day in the applicable month or such other day as we deem appropriate. Your renewal date may change due to changes in your subscription. We may authorize your Payment Method in anticipation of subscription or service-related charges. </p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">As used in these Terms of Service, billing shall indicate a charge, debit or other payment clearance, as applicable, against your Payment Method. Unless otherwise stated differently, month or monthly refers to your billing cycle.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You will not receive a refund for the fees you already paid for your current subscription period and you will continue to receive the Services ordered until the end of your current Subscription period.</p>
   <h3>No Refunds</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">All payments made are non-refundable and non- transferable except as expressly provided in these Terms. If you have any concerns or objections regarding charges, you agree to raise them with us first and you agree not to cancel or reject any credit card or third party payment processing charges unless you have made a reasonable attempt at resolving the matter directly with Kisstasy. While you may be able to cancel your subscription at any time, refunds are issued in Kisstasy sole discretion, unless otherwise required by applicable law.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Kisstasy reserves the right to not process or to cancel your order in certain circumstances, for example, if your credit card is declined if we suspect the request or order is fraudulent, or in other circumstances, Kisstasy deems appropriate in its sole discretion. Kisstasy also reserves the right, in its sole discretion, to take steps to verify your identity in connection with your order. You may need to provide additional information to verify your identity before completing your Transaction (such information is included within the definition of Payment Information). Kisstasy will either not charge you or refund the charges for orders that we do not process or cancel.</p>
   <h3>Changes to Subscriptions Prices</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">We reserve the right to adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole discretion. Except as otherwise expressly provided for in these Terms of Service, any material price increases to your service will take effect following notice to you.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Changes to the pricing terms will not apply retroactively and will only apply for Subscription renewals after such changed pricing terms have been communicated to you. If you do not agree with the changes to Kisstasy’s pricing terms then you may choose not to renew your Subscription in accordance with the Cancellation policy.</p>
   <h3>Cancellation</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You can cancel your Kisstasy Subscription at any time, and you will continue to have access to the Kisstasy service through the end of your billing period. To the extent permitted by the applicable law, payments are non-refundable and we do not provide refunds or credits for any partial membership periods or contents that you did not listen to. To cancel, go to the “Account“ page and follow the instructions for cancellation. If you cancel your subscription, your account will automatically close at the end of your current billing period</p>
   <h3>Future Functionality</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You agree that your purchases are not contingent on the delivery of any future functionality or features, or dependent on any oral or written public comments made by Kisstasy regarding future functionality or features.</p>
   <h2>Feedback</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">We welcome feedback, comments and suggestions for improvements to the Services or Products (“Feedback”). You can submit Feedback by emailing us at feedback@Kisstasy.com. You grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sublicenseable and transferable license under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit the Feedback for any purpose.</p>
   <h2>Content Ownership and Intellectual Property Rights</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">The Website and its entire contents, features, and functionality (including all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement of it) are owned by the Kisstasy, its licensors, or other providers of that material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. </p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">This agreement permits you to use the Website for your personal, noncommercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Website, except as follows:</p>
   <ol>
      <li>Your computer may temporarily store copies of those materials in RAM incidental to your accessing and viewing those materials.</li>
      <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
      <li>You may print or download one copy of a reasonable number of pages of the Website for your own personal, noncommercial use and not for further reproduction, publication, or distribution.</li>
      <li>You may download or print a copy of any part of the premium content to which you have properly gained access solely for your personal, noncommercial use and not for further reproduction, publication, or distribution.</li>
      <li>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, noncommercial use, on condition that you agree to be bound by the Company’s end user license agreement for those applications.</li>
   </ol>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You acknowledge that the Services and Content are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content.</p>
   <h2>User Generated Contents and Contributions</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">The Website may contain message boards, profiles, forums, bulletin boards, and other interactive features (collectively, “Interactive Services”) that allow users to post, submit, publish, display, or transmit to the Model, other users, or other persons (“post”) content or materials (collectively, “User Content”) on or through the Website.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You may upload certain User Content from time to time for a certain aspect of the services. You guarantee that you own all rights in the User Content and you acknowledge and agree that you have sole responsibility for the legality, reliability, accuracy and quality of the User Content.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You grant to us a non-exclusive, royalty-free, worldwide license to use the User Content to the extent reasonably necessary for the provision of the Services from time to time. This right cannot be withdrawn by you (but you can delete the content from any public-facing elements of the Website) and the license may be used by us on an ongoing basis. For the avoidance of doubt, by posting User Content publicly to a Service, you grant us the right and license to use, modify, display, reproduce and distribute such User Content for any purpose.</p>
   <h2>Rights and Terms for Apps</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Rights in App Granted by Kisstasy</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Subject to your compliance with these Terms, Kisstasy grants you a limited non- exclusive, non-transferable, non-sublicenseable license to download and install a copy of the App on a mobile device or computer that you own or control and to run such copy of the App solely for your own personal non-commercial purposes. Kisstasy reserves all rights in and to the App not expressly granted to you under these Terms. You may not copy the App, except for making a reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms, you may not: (i) copy, modify or create derivative works based on the App; (ii) distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii) reverse engineer, decompile or disassemble the App; or (iv) make the functionality of the App available to multiple users through any means.</p>
   <h2>Additional Terms for App Store Apps</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">If you accessed or downloaded the App from the Apple Store, then you agree to use the App only: (i) on an Apple-branded product or device that runs iOS (Apple’s proprietary operating system software); and (ii) as permitted by the “Usage Rules” set forth in the Apple Store Terms of Service. If you accessed or downloaded the App from an App Provider, then you acknowledge and agree that:</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">These Terms are concluded between you and Kisstasy, and not with App Provider, and that, as between Kisstasy and the App Provider, Kisstasy, is solely responsible for the App.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">App Provider has no obligation to furnish any maintenance and support services with respect to the App.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs"> In the event of any failure of the App to conform to any applicable warranty, you may notify App Provider and App Provider will refund the purchase price for the App to you (if applicable) and, to the maximum extent permitted by applicable law, App Provider will have no other warranty obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure of an App to conform to any warranty will be the sole responsibility of Kisstasy.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">App Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">In the event of any third-party claim that the App or your possession and use of the App infringe that third party’s intellectual property rights, Kisstasy will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">App Provider and its subsidiaries are third-party beneficiaries of these Terms as related to your license of the App, and that, upon your acceptance of the terms and conditions of these Terms, App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App against you as a third-party beneficiary thereof.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You must also comply with all applicable third-party terms of service when using the App.</p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You agree to comply with all U.S. and foreign export laws and regulations to ensure that neither the App nor any technical data related thereto nor any direct product thereof is exported or re-exported directly or indirectly in violation of or used for any purposes prohibited by such laws and regulations. By using the App you represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</p>
   <h2>Prohibitions</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You agree not to do any of the following:</p>
   <ol>
      <li>Post, upload, publish, submit or transmit any Content that: (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances;</li>
      <li>Use, display, mirror or frame the Services, or any individual element within the Services, Kisstasy’s name, any Kisstasy trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without Kisstasy’s express written consent;</li>
      <li>Access, tamper with, or use non-public areas of the Services, Kisstasy’s computer systems, or the technical delivery systems of Kisstasy’s providers;</li>
      <li>Attempt to probe, scan, or test the vulnerability of any Kisstasy system or network or breach any security or authentication measures;</li>
      <li>void, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Kisstasy or any of Kisstasy’s providers or any other third party (including another user) to protect the Services;</li>
      <li>Attempt to access or search the Services or download Collective Content from the Services through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Kisstasy or other generally available third party web browsers;</li>
      <li>Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other forms of solicitation;</li>
      <li>Use any meta tags or other hidden text or metadata utilizing an Kisstasy trademark, logo URL or product name without Kisstasy’s express written consent;&lt;/li&gt;</li>
      <li>Use the Services for any commercial purpose or the benefit of any third party or in any manner not permitted by these Terms;</li>
      <li>Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services to send altered, deceptive or false source-identifying information;</li>
      <li>Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services;</li>
      <li>Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;</li>
      <li>Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;</li>
      <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
      <li>Violate any applicable law or regulation; or</li>
      <li>Encourage or enable any other individual to do any of the foregoing.</li>
   </ol>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Although we’re not obligated to monitor access to or use of the Services or Content or to review or edit any Content, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms, and to comply with applicable law or other legal requirements. We reserve the right but are not obligated, to remove or disable access to any Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider any Content to be objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.</p>
   <h2>Links to Third-Party Websites or Resources</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">The Services and App may contain links to third-party websites or resources. We provide these links only as a convenience a
      nd are not responsible for the content, products or services on or available from those websites or resources or links dis
      played on such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any third-party websites
      or resources.
   </p>
   <h2>Termination</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you. You may cancel your Account at any time by sending an email to us at support@Kisstasy.com. If you purchase Subscription via an App Provider, you should also cancel your Subscription with the App Provider directly. Upon any termination, discontinuation or cancellation of
      Services or your Account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions.
   </p>
   <h2>Warranty Disclaimers</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">The Services, Products and Content are provided “as is,” without warranty of any kind. Without limiting the foregoing, we explicitly disclaim any warranties of merchantability, fitness for a particular purpose, quiet
      enjoyment or non- infringement and any warranties arising out of course of dealing or usage of trade.
   </p>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">We make no warranty that the Services or
      Products will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, c
      ompleteness or reliability of any Content.
   </p>
   <h2>Indemnity</h2>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You will indemnify and hold harmless Kisstasy and its officers, directors, employee
      and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees, ari
      sing out of or in any way connected with (i) your access to or use of the Services or Content or (ii) your violation of these Terms.
   </p>
   <h2>Limitation of Liability</h2>
   <p class="Text-sc-1
      j3qsx4-0 hfKdPs">Neither Kisstasy nor any other party involved in creating, producing, or delivering the Services, Products or Content will be liable for any incidental, special, exem
      plary or consequential damages, including, but not limited to, lost profits, loss of data or goodwill, service interruption, computer damage or system failure or the cost of substitute Se
      rvices or Products arising out of or in connection with these terms or from the use of or inability to use the Services, Products or Content, whether based on warranty, contract, tort (in
      cluding negligence), product liability or any other legal theory, and whether or not Kisstasy has been informed of the possibility of such damage, even if a limited remedy set forth h
      erein is found to have failed of its essential purpose. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limi
      tation may not apply to you. In no event will Kisstasy’ total liability arising out of or in connection with these terms or from the use of or inability to use the Services, Products
      or Content exceed the amounts you have paid to Kisstasy for use of the Services, Products or Content or fifty dollars ($50), if you have not had any payment obligations to Kisstasy
      , as applicable. The exclusion and limitations of damages set forth above are fundamental elements of the basis of the bargain between Kisstasy and you.
   </p>
   <h2>Dispute Resolution</h2>
   <h3>Governing Law</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">These Terms and any action related thereto will be governed by the laws of the State of California without regard to its conflict
      of laws provisions.
   </p>
   <h3>Agreement to Arbitrate</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You and Kisstasy agree that any dispute, claim or controversy arising out of or relating to t
      hese Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services, Products or Content (collectively, “Disputes”) will be settled by bindin
      g arbitration, except that each party retains the right: (i) to bring an individual action in small claims court and (ii) to seek injunctive or other equitable relief in a court of compet
      ent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property
      rights (the action described in the foregoing clause (ii), an “IP Protection Action”). Without limiting the preceding sentence, you will also have the right to litigate any other Dispute
      if you provide Kisstasy with written notice of your desire to do so by email at support@Kisstasy.com within thirty (30) days following the date you first agree to these Terms (s
      uch notice, an “Arbitration Opt-out Notice”). If you don’t provide Kisstasy with an Arbitration Opt-out Notice within the thirty (30) day period, you will be deemed to have knowingl
      y and intentionally waived your right to litigate any Dispute except as expressly set forth in clauses (i) and (ii) above. The exclusive jurisdiction and venue of any IP Protection Acti
      on or, if you timely provide Kisstasy with an Arbitration Opt-out Notice, will be the state and federal courts located in the Northern District of California and each of the parties
      hereto waives any objection to jurisdiction and venue in such courts. Unless you timely provide Kisstasy with an Arbitration Opt-out Notice, you acknowledge and agree that you and
      Kisstasy are each waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Further, unless
      both you and Kisstasy otherwise agree in writing, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of any class or repr
      esentative proceeding. If this specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed void. Except as provided in the preceding
      sentence, this “Dispute Resolution” section will survive any termination of these Terms
   </p>
   <h3>Arbitration Rules</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">The arbitration will be admini
      stered by the American Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer-Related Disputes (the “AAA Rul
      es”) then in effect, except as modified by this “Dispute Resolution” section. (The AAA Rules are available at www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal
      Arbitration Act will govern the interpretation and enforcement of this Section.
   </p>
   <h3>Arbitration Process</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">A party who desires to initiate arb
      itration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. (The AAA provides a general Demand for Arbitration form and a separate form for Demand
      for Arbitration for California residents.) The arbitrator will be either a retired judge or an attorney licensed to practice law and will be selected by the parties from the AAA’s roster of arbi
      trators. If the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the AA
      A Rules.
   </p>
   <h3>Arbitration Location and Procedure</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Unless you and Kisstasy otherwise agree, the arbitration will be conducted in the county where you
      reside. If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of the documents that you and Kisstasy submit to the arbitrator, unless you request
      a hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the arbi
      trator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration.
   </p>
   <h3>Arbitrator’s Decision</h3>
   <p class=
      "Text-sc-1j3qsx4-0 hfKdPs">The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions upo
      n which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s award of damages must be consistent with the t
      erms of the “Limitation of Liability” section above as to the types and amounts of damages for which a party may be held liable. The arbitrator may award declaratory or injunctive relief only in
      favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. If you prevail in arbitration you will be entitled to an award of attorney
      s’ fees and expenses, to the extent provided under applicable law. Kisstasy will not seek, and hereby waives all rights it may have under applicable law to recover, attorneys’ fees and expe
      nses if it prevails in arbitration.
   </p>
   <h3>Fees</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Your responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth
      in the AAA Rules. However, if your claim for damages does not exceed $75,000, Kisstasy will pay all such fees unless the arbitrator finds that either the substance of your claim or the rel
      ief sought in your Demand for Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)).
   </p>
   <h3>Changes
   </h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Notwithstanding the provisions as regards how we can make changes to this Terms, if Kisstasy changes this “Dispute Resolution” section after the da
      te you first accepted these Terms (or accepted any subsequent changes to these Terms), you may reject any such change by sending us written notice (including by email to feedback@Kisstasy.
      com) within 30 days of the date such change became effective, as indicated in the “Last Updated” date above or in the date of Kisstasy’s email to you notifying you of such change. By rejec
      ting any change, you are agreeing that you will arbitrate any Dispute between you and Kisstasy in accordance with the provisions of this “Dispute Resolution” section as of the date you fir
      st accepted these Terms (or accepted any subsequent changes to these Terms)
   </p>
   <h2>General Terms</h2>
   <h3>Entire Agreement:</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">These Terms constitute the en
      tire and exclusive understanding and agreement between Kisstasy and you regarding the Services, Products and Content, and these Terms supersede and replace any and all prior oral or writte
      n understandings or agreements between Kisstasy and you regarding the Services, Products and Content. 
   </p>
   <h3>Severability:</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">If any provision of thes
      e Terms is held invalid or unenforceable (either by an arbitrator appointed pursuant to the terms of the “Arbitration” section above or by a court of competent jurisdiction, but only if you ti
      mely opt-out of arbitration by sending us an Arbitration Opt-out Notice in accordance with the terms set forth above), that provision will be enforced to the maximum extent permissible and the
      other provisions of these Terms will remain in full force and effect. 
   </p>
   <h3>Assignment:</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">You may not assign or transfer these Terms, by operation of l
      aw or otherwise, without Kisstasy’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null and of no effect. Kisstasy may free
      ly assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns. 
   </p>
   <h3
      >Notices:</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Any notices or other communications provided by Kisstasy under these Terms, including those regarding modifications to these Terms, will
      be given: (i) by Kisstasy via email; or (ii) by posting to the Services. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted. 
   </p>
   <
   h3>No Waiver:</h3>
   <p class="Text-sc-1j3qsx4-0 hfKdPs">Kisstasy’s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The
      waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Kisstasy. Except as expressly set forth in these Terms, the
      exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.
   </p>
   <h2>Contact Information</h2>
   <p class="
      Text-sc-1j3qsx4-0 hfKdPs">If you have any questions about these Terms or the Services or Products, please contact Kisstasy at support@Kisstasy.com.</p>
</div>


        </Text>
      </PrivacyPolicy>
    </>
  )
}
export default Terms